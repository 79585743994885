import React, { useEffect } from "react"
import Services from "../components/Services-Page/Content"

import Layout from "../components/Layout"
import { Helmet } from "react-helmet"
export default function ServicesPage() {
  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://codefulcrum.com/services/" />
      </Helmet>
      <Services />
    </Layout>
  )
}
