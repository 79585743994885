import React from "react"

import Button from "../shared/Button"
import Heading from "../shared/Heading"
import Scroll from "../utils/Scroll"
export default function Hero({
  btnText,
  img,
  children,
  text,
  social,
  scroll,
  id,
}) {
  return (
    <div className="services-hero">
      <div className="hero-bg" id={id}>
        <div className="container position-relative">
          <div className="row">
            <div
              className="col-lg-5 hero-text-column"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-anchor-placement="bottom-bottom"
            >
              <Heading variant="58" className="header">
                <h1 className="p-1">A Code-Focused Agency</h1>
              </Heading>
              {!!text ? <div className="hero-text">{text}</div> : null}
              <div className="button text-capitalize">
                <Button
                  text={btnText}
                  variant="fill"
                  href="/contact"
                  className="text-capitalize"
                />
              </div>
            </div>
            <div className="col-lg-7 mt-sm-0 mt-5">
              <img
                src={img}
                alt="agency-hero"
                className="img-fluid"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
