import React from "react"

function Icon() {
  return (
    <img
      loading="lazy"
      src="/dropdown-arrow.svg"
      style={{ width: "18px" }}
      alt="drop-down-icon"
    />
  )
}

export default Icon
